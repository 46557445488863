import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getActivites = createAsyncThunk('GET ACTIVITIES', async (data, thunkAPI) => {
  let query = `?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&sorting=${data.sorting}&userType=${data.userType}&sortingField=${data.sortingField}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}&cogs=${data.cogs}&cogsPaidDate=${data.cogsPaidDate}`;
  if (data?.agencyId) {
    query = `?agencyId=${data?.agencyId}&page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&sorting=${data.sorting}&userType=${data.userType}&sortingField=${data.sortingField}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}&cogs=${data.cogs}&cogsPaidDate=${data.cogsPaidDate}`;
  }
  const response = await axios.get(`${data?.agencyId ? URL.AGENCY_ACTIVITIES : URL.ACTIVITY}${query}`, thunkAPI);
  return response.data;
});

export const getActivityDetails = createAsyncThunk('GET ACTIVITY DETAILS', async (data, thunkAPI) => {
  const response = await axios.get(`${URL.ACTIVITY}/${data.referenceNumber}`, thunkAPI);
  return response.data;
});

export const resendEmailConfirmation = createAsyncThunk(
  'RESEND_ACTIVITY_EMAIL_CONFIRMATION',
  async (data, thunkAPI) => {
    const response = await axios.post(URL.RESEND_ACTIVITY_CONFIRMATION_EMAIL, data, thunkAPI);
    return response.data;
  }
);

export const updateActivityRemark = createAsyncThunk('UPDATE_ACTIVITY_REMARKS', async (data, thunkAPI) => {
  const response = await axios.post(URL.UPDATE_ACTIVITY_REMARKS, data, thunkAPI);
  return response.data;
});

export const updateActivityBooking = createAsyncThunk('UPDATE_ACTIVITY_BOOKING', async (data, thunkAPI) => {
  const response = await axios.patch(`${URL.UPDATE_ACTIVITY_BOOKING}/${data.referenceNumber}`, data, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  activityData: null,
  activityDetails: null,
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {},
  extraReducers: {
    // get activities
    [getActivites.pending]: (state) => {
      state.isLoading = true;
      state.activityData = null;
    },
    [getActivites.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.activityData = action.payload.data;
    },
    [getActivites.rejected]: (state, action) => {
      state.isLoading = false;
      state.activityData = null;
      toast.error(action?.payload?.message);
    },

    // update activity remarks
    [updateActivityRemark.pending]: (state) => {
      state.isLoading = true;
    },
    [updateActivityRemark.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Remarks updated successfully');
    },
    [updateActivityRemark.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload?.message || 'Error updating remarks');
    },

    // get activity Details
    [getActivityDetails.pending]: (state) => {
      state.isLoading = true;
      state.activityDetails = null;
    },
    [getActivityDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.activityDetails = action.payload.data;
    },
    [getActivityDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.activityDetails = null;
      toast.error(action?.payload?.message);
    },

    // resend hotel confirmation email
    [resendEmailConfirmation.pending]: (state) => {
      state.isLoading = true;
    },
    [resendEmailConfirmation.fulfilled]: (state) => {
      state.isLoading = false;
      // Handle success, e.g., show a success toast message
      toast.success('Email confirmation resent successfully');
    },
    [resendEmailConfirmation.rejected]: (state, action) => {
      state.isLoading = false;
      // Handle error, e.g., show an error toast message
      toast.error(action.payload?.message || 'Error resending email confirmation');
    },

    // update activity booking
    [updateActivityBooking.pending]: (state) => {
      state.isLoading = true;
    },
    [updateActivityBooking.fulfilled]: (state, action) => {
      state.isLoading = false;
      toast.success(action.payload?.message);
    },
    [updateActivityBooking.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload?.message || 'Error updating cogs data');
    },
  },
});

export default activitySlice.reducer;
