import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getDashboard = createAsyncThunk('DASHBOARD INFO', async (thunkAPI) => {
  const response = await axios.get(URL.GET_DASHBOARD, thunkAPI);
  return response.data;
});

export const getTodayBookings = createAsyncThunk('TODAY BOOKINGS', async (data, thunkAPI) => {
  const response = await axios.get(
    `${URL.GET_TODAY_BOOKINGS}?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}`,
    thunkAPI
  );
  return response.data;
});

export const getAgencyChartData = createAsyncThunk('GET AGENCY CHART DATA', async (data, thunkAPI) => {
  const response = await axios.get(URL.GET_AGENCY_CHART, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  isTodayBookingLoading: false,
  isAgencyChartLoading: false,
  dashboard: null,
  todayBookings: null,
  agencyChartData: null,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {
    // dashboard info
    [getDashboard.pending]: (state) => {
      state.isLoading = true;
      state.dashboard = null;
    },
    [getDashboard.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.dashboard = action.payload.data;
    },
    [getDashboard.rejected]: (state, action) => {
      state.isLoading = false;
      state.dashboard = null;
      toast.error(action?.payload?.message);
    },

    // today bookings
    [getTodayBookings.pending]: (state) => {
      state.isTodayBookingLoading = true;
      state.todayBookings = null;
    },
    [getTodayBookings.fulfilled]: (state, action) => {
      state.isTodayBookingLoading = false;
      state.todayBookings = action.payload.data;
    },
    [getTodayBookings.rejected]: (state, action) => {
      state.isTodayBookingLoading = false;
      state.todayBookings = null;
      toast.error(action?.payload?.message);
    },

    // today bookings
    [getAgencyChartData.pending]: (state) => {
      state.isAgencyChartLoading = true;
      state.agencyChartData = null;
    },
    [getAgencyChartData.fulfilled]: (state, action) => {
      state.isAgencyChartLoading = false;
      state.agencyChartData = action.payload.data;
    },
    [getAgencyChartData.rejected]: (state, action) => {
      state.isAgencyChartLoading = false;
      state.agencyChartData = null;
      toast.error(action?.payload?.message);
    },
  },
});

export default dashboardSlice.reducer;
