import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import axios from 'utils/axios';
import URL from 'config/url';

const cookies = new Cookies();

export const userLogin = createAsyncThunk('USER/LOGIN', async (data, thunkAPI) => {
  const response = await axios.post(URL.LOGIN, data, thunkAPI);
  return response.data;
});

export const getUserProfile = createAsyncThunk('USER/DETAILS', async (thunkAPI) => {
  const response = await axios.get(URL.USER_DETAILS, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  userDetails: null,
  authToken: cookies.get('x-access-token'),
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.isLoading = true;
      state.authToken = null;
    },
    [userLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.authToken = action.payload.data.auth;
      cookies.set('x-access-token', action.payload.data.auth);
    },
    [userLogin.rejected]: (state, action) => {
      state.isLoading = false;
      state.authToken = null;
      toast.error(action?.payload?.message);
    },
    [getUserProfile.pending]: (state) => {
      state.isLoading = true;
      state.userDetails = null;
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userDetails = action.payload.data;
    },
    [getUserProfile.rejected]: (state, action) => {
      state.isLoading = false;
      state.userDetails = null;
      toast.error(action?.payload?.message);
    },
  },
});

export default userSlice.reducer;
