import React from 'react';
import Cookies from 'universal-cookie';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Row, Image } from 'antd';
import Logo from 'assets/images/logo.png';
import { useSelector } from 'react-redux';

const cookies = new Cookies();

const Header = () => {
  const handleLogout = () => {
    ['loginInfo', 'loginTrue', 'isTrue'].forEach((item) => cookies.remove(item, { path: '/' }));
    localStorage.clear();
    cookies.remove('x-access-token', {
      path: '/',
    });
    document.location = '/';
  };

  const items = [
    {
      key: '1',
      label: <div onClick={handleLogout}>Logout</div>,
    },
  ];
  const userDetails = useSelector((state) => state.user.userDetails);
  return (
    <Row className={`${userDetails ? 'justify-content-end' : 'justify-content-start'} align-items-center h-full`}>
      {!userDetails && <Image src={Logo} alt="logo" preview={false} width={100} className="m-3" />}
      {userDetails && (
        <Dropdown menu={{ items }} trigger={['click']} className="bg-purple-600">
          <Avatar size={46} icon={<UserOutlined />} alt="avatar" />
        </Dropdown>
      )}
    </Row>
  );
};

export default Header;
