import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getDefaultPlatformMarkup = createAsyncThunk('GET DEFAULT PLATFORM MARKUP', async (thunkAPI) => {
  const response = await axios.get(URL.GET_DEFAULT_PLATFORM_MARKUP, thunkAPI);
  return response.data;
});

export const updateDefaultPlatformMarkup = createAsyncThunk(
  'UPDATE DEFAULT PLATFORM MARKUP',
  async (data, thunkAPI) => {
    const response = await axios.patch(URL.GET_DEFAULT_PLATFORM_MARKUP, data, thunkAPI);
    return response.data;
  }
);

export const getServiceMarkup = createAsyncThunk('GET SERVICE MARKUP', async (thunkAPI) => {
  const response = await axios.get(URL.GET_SERVICE_MARKUP, thunkAPI);
  return response.data;
});

export const updateServiceMarkup = createAsyncThunk('UPDATE SERVICE MARKUP', async (data, thunkAPI) => {
  const response = await axios.patch(URL.GET_SERVICE_MARKUP, data, thunkAPI);
  return response.data;
});

export const getDefaultAgencyMarkup = createAsyncThunk('GET DEFAULT AGENCY MARKUP', async (thunkAPI) => {
  const response = await axios.get(URL.DEFAULT_AGENCY_MARKUP, thunkAPI);
  return response.data;
});

export const updateDefaultAgencyMarkup = createAsyncThunk('UPDATE DEFAULT AGENCY MARKUP', async (data, thunkAPI) => {
  const response = await axios.patch(URL.DEFAULT_AGENCY_MARKUP, data, thunkAPI);
  return response.data;
});

export const getAgencyMarkup = createAsyncThunk('GET AGENCY MARKUP', async (data, thunkAPI) => {
  const response = await axios.get(
    `${URL.AGENCY_MARKUP}?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text || ''}`,
    thunkAPI
  );
  return response.data;
});

export const updateAgencyMarkup = createAsyncThunk('UPDATE AGENCY MARKUP', async (data, thunkAPI) => {
  const response = await axios.patch(URL.AGENCY_MARKUP, data, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  markups: null,
  updatedMarkup: null,
};

export const markupSlice = createSlice({
  name: 'markup',
  initialState,
  reducers: {
    resetUpdatedMarkup: (state) => {
      state.updatedMarkup = null;
    },
  },
  extraReducers: {
    // get default platform markup
    [getDefaultPlatformMarkup.pending]: (state) => {
      state.isLoading = true;
      state.markups = null;
    },
    [getDefaultPlatformMarkup.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markups = action.payload.data;
    },
    [getDefaultPlatformMarkup.rejected]: (state, action) => {
      state.isLoading = false;
      state.markups = null;
      toast.error(action?.payload?.message);
    },

    //update default platform markup

    [updateDefaultPlatformMarkup.pending]: (state) => {
      state.isLoading = true;
      state.updatedMarkup = null;
    },
    [updateDefaultPlatformMarkup.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.updatedMarkup = action.payload.data.markup;
    },
    [updateDefaultPlatformMarkup.rejected]: (state, action) => {
      state.isLoading = false;
      state.updatedMarkup = null;
      toast.error(action?.payload?.message);
    },

    //get service markup

    [getServiceMarkup.pending]: (state) => {
      state.isLoading = true;
      state.markups = null;
    },
    [getServiceMarkup.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markups = action.payload.data;
    },
    [getServiceMarkup.rejected]: (state, action) => {
      state.isLoading = false;
      state.markups = null;
      toast.error(action?.payload?.message);
    },

    //update service markup

    [updateServiceMarkup.pending]: (state) => {
      state.isLoading = true;
      state.updatedMarkup = null;
    },
    [updateServiceMarkup.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.updatedMarkup = action.payload.data.markup;
    },
    [updateServiceMarkup.rejected]: (state, action) => {
      state.isLoading = false;
      state.updatedMarkup = null;
      toast.error(action?.payload?.message);
    },

    //get default agency markup

    [getDefaultAgencyMarkup.pending]: (state) => {
      state.isLoading = true;
      state.markups = null;
    },
    [getDefaultAgencyMarkup.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markups = action.payload.data;
    },
    [getDefaultAgencyMarkup.rejected]: (state, action) => {
      state.isLoading = false;
      state.markups = null;
      toast.error(action?.payload?.message);
    },

    //update default agency markup

    [updateDefaultAgencyMarkup.pending]: (state) => {
      state.isLoading = true;
      state.updatedMarkup = null;
    },
    [updateDefaultAgencyMarkup.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { markups } = state;
      const data = markups.markups.map((el) => {
        if (el?.subscriptionPlan === action.payload.data.markup?.subscriptionPlan) {
          return {
            ...action.payload.data.markup,
          };
        }
        return el;
      });
      state.markups = { markups: data, count: markups?.count };
    },
    [updateDefaultAgencyMarkup.rejected]: (state, action) => {
      state.isLoading = false;
      state.updatedMarkup = null;
      toast.error(action?.payload?.message);
    },

    //get agency markup

    [getAgencyMarkup.pending]: (state) => {
      state.isLoading = true;
      state.markups = null;
    },
    [getAgencyMarkup.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markups = action.payload.data;
    },
    [getAgencyMarkup.rejected]: (state, action) => {
      state.isLoading = false;
      state.markups = null;
      toast.error(action?.payload?.message);
    },

    //update agency markup

    [updateAgencyMarkup.pending]: (state) => {
      state.isLoading = true;
      state.updatedMarkup = null;
    },
    [updateAgencyMarkup.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { markups } = state;
      const data = markups.markups.map((el) => {
        if (el?.id === action.payload.data.markup?.id) {
          return {
            ...el,
            ...action.payload.data.markup,
          };
        }
        return el;
      });
      state.markups = { markups: data, count: markups?.count };
    },
    [updateAgencyMarkup.rejected]: (state, action) => {
      state.isLoading = false;
      state.updatedMarkup = null;
      toast.error(action?.payload?.message);
    },
  },
});

export const { resetUpdatedMarkup } = markupSlice.actions;
export default markupSlice.reducer;
