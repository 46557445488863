import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getCars = createAsyncThunk('GET CARS', async (data, thunkAPI) => {
  let query = `?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&provider=${data.provider}&startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&sorting=${data.sorting}&userType=${data.userType}&sortingField=${data.sortingField}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}&cogs=${data.cogs}&cogsPaidDate=${data.cogsPaidDate}`;
  if (data?.agencyId) {
    query = `?agencyId=${data?.agencyId}&page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&provider=${data.provider}&startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&sorting=${data.sorting}&userType=${data.userType}&sortingField=${data.sortingField}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}&cogs=${data.cogs}&cogsPaidDate=${data.cogsPaidDate}`;
  }
  const response = await axios.get(`${data?.agencyId ? URL.AGENCY_CARS : URL.CAR}${query}`, thunkAPI);
  return response.data;
});

export const getCarDetails = createAsyncThunk('GET CAR DETAILS', async (data, thunkAPI) => {
  const response = await axios.get(`${URL.CAR}/${data.referenceNumber}`, thunkAPI);
  return response.data;
});

export const resendEmailConfirmation = createAsyncThunk('RESEND_CAR_EMAIL_CONFIRMATION', async (data, thunkAPI) => {
  const response = await axios.post(URL.RESEND_CAR_CONFIRMATION_EMAIL, data, thunkAPI);
  return response.data;
});

export const updateCarRemark = createAsyncThunk('UPDATE_CAR_REMARKS', async (data, thunkAPI) => {
  const response = await axios.post(URL.UPDATE_CAR_REMARKS, data, thunkAPI);
  return response.data;
});

export const updateCarBooking = createAsyncThunk('UPDATE_CAR_BOOKING', async (data, thunkAPI) => {
  const response = await axios.patch(`${URL.UPDATE_CAR_BOOKING}/${data.referenceNumber}`, data, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  carData: null,
  carDetails: null,
};

export const carSlice = createSlice({
  name: 'car',
  initialState,
  reducers: {},
  extraReducers: {
    // get cars
    [getCars.pending]: (state) => {
      state.isLoading = true;
      state.carData = null;
    },
    [getCars.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.carData = action.payload.data;
    },
    [getCars.rejected]: (state, action) => {
      state.isLoading = false;
      state.carData = null;
      toast.error(action?.payload?.message);
    },

    // update car remarks
    [updateCarRemark.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCarRemark.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Car remarks updated successfully');
    },
    [updateCarRemark.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload?.message || 'Error updating car remarks');
    },
    // get activity Details
    [getCarDetails.pending]: (state) => {
      state.isLoading = true;
      state.carDetails = null;
    },
    [getCarDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.carDetails = action.payload.data;
    },
    [getCarDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.carDetails = null;
      toast.error(action?.payload?.message);
    },

    // resend hotel confirmation email
    [resendEmailConfirmation.pending]: (state) => {
      state.isLoading = true;
    },
    [resendEmailConfirmation.fulfilled]: (state) => {
      state.isLoading = false;
      // Handle success, e.g., show a success toast message
      toast.success('Email confirmation resent successfully');
    },
    [resendEmailConfirmation.rejected]: (state, action) => {
      state.isLoading = false;
      // Handle error, e.g., show an error toast message
      toast.error(action.payload?.message || 'Error resending email confirmation');
    },

    // update car booking
    [updateCarBooking.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCarBooking.fulfilled]: (state, action) => {
      state.isLoading = false;
      toast.success(action.payload?.message);
    },
    [updateCarBooking.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload?.message || 'Error updating cogs data');
    },
  },
});

export default carSlice.reducer;
