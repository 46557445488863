import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getCountires = createAsyncThunk('GET COUNTRIES', async (data, thunkAPI) => {
  let query = `?page=${data.page}&limit=${data.limit}&text=${data.text}`;

  const response = await axios.get(`${URL.COUNTRY}${query}`, thunkAPI);
  return response.data;
});

export const addCountry = createAsyncThunk('ADD COUNTRY', async (data, thunkAPI) => {
  const response = await axios.post(URL.COUNTRY, data, thunkAPI);
  return response.data;
});

export const updateCountry = createAsyncThunk('UPDATE COUNTRY', async (data, thunkAPI) => {
  const response = await axios.put(URL.COUNTRY, data, thunkAPI);
  return response.data;
});

export const deleteCountry = createAsyncThunk('DELETE COUNTRY', async (data, thunkAPI) => {
  const response = await axios.delete(`${URL.COUNTRY}?id=${data}`, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  countriesData: null,
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: {
    // get countries
    [getCountires.pending]: (state) => {
      state.isLoading = true;
      state.countriesData = [];
    },
    [getCountires.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.countriesData = action.payload.data;
    },
    [getCountires.rejected]: (state, action) => {
      state.isLoading = false;
      state.countriesData = [];
      toast.error(action?.payload?.message);
    },

    // add country
    [addCountry.pending]: (state) => {
      state.isLoading = true;
    },
    [addCountry.fulfilled]: (state, action) => {
      state.isLoading = false;
      toast.success(action.payload.message);
    },
    [addCountry.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action?.payload?.message);
    },

    // update country
    [updateCountry.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCountry.fulfilled]: (state, action) => {
      state.isLoading = false;
      toast.success(action.payload.message);
    },
    [updateCountry.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action?.payload?.message);
    },

    // delete country
    [deleteCountry.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteCountry.fulfilled]: (state, action) => {
      state.isLoading = false;
      toast.success(action.payload.message);
    },
    [deleteCountry.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action?.payload?.message);
    },
  },
});

export default countriesSlice.reducer;
