import React from 'react';
import { Image } from 'antd';
import LoaderGif from 'assets/images/loader.gif';

const Loading = () => {
  return (
    <div
      className="flex align-items-center justify-content-center w-full h-full fixed z-50"
      style={{ background: 'rgba(245, 241, 241, 0.6)' }}
    >
      <Image src={LoaderGif} className="w-30" preview={false} />
    </div>
  );
};

export default Loading;
