import React, { lazy, Suspense } from 'react';
import Loader from 'components/Loader/Loading';

const LazyLoading = (importFunc, { fallback = null } = { fallback: null }) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={fallback || Loader || 'Loading...'}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default LazyLoading;
