import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getLocations = createAsyncThunk('GET LOCATIONS', async (data, thunkAPI) => {
    let query = `?page=${data.page}&limit=${data.limit}&text=${data.text}`;

    const response = await axios.get(`${URL.LOCATIONS}${query}`, thunkAPI);
    return response.data;
});

export const addLocation = createAsyncThunk('ADD LOCATION', async (data, thunkAPI) => {
    const response = await axios.post(URL.LOCATIONS, data, thunkAPI);
    return response.data;
});

export const updateLocation = createAsyncThunk('UPDATE LOCATION', async (data, thunkAPI) => {
    const response = await axios.put(URL.LOCATIONS, data, thunkAPI);
    return response.data;
});

export const deleteLocation = createAsyncThunk('DELETE LOCATION', async (data, thunkAPI) => {
    const response = await axios.delete(`${URL.LOCATIONS}?id=${data}`, thunkAPI);
    return response.data;
});

const initialState = {
    isLoading: false,
    locationsData: null,
};

export const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {},
    extraReducers: {
        // get locations
        [getLocations.pending]: (state) => {
            state.isLoading = true;
            state.locationsData = [];
        },
        [getLocations.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.locationsData = action.payload.data;
        },
        [getLocations.rejected]: (state, action) => {
            state.isLoading = false;
            state.locationsData = [];
            toast.error(action?.payload?.message);
        },

        // add locations
        [addLocation.pending]: (state) => {
            state.isLoading = true;
        },
        [addLocation.fulfilled]: (state, action) => {
            state.isLoading = false;
            toast.success(action.payload.message);
        },
        [addLocation.rejected]: (state, action) => {
            state.isLoading = false;
            toast.error(action?.payload?.message);
        },

        // update locations
        [updateLocation.pending]: (state) => {
            state.isLoading = true;
        },
        [updateLocation.fulfilled]: (state, action) => {
            state.isLoading = false;
            toast.success(action.payload.message);
        },
        [updateLocation.rejected]: (state, action) => {
            state.isLoading = false;
            toast.error(action?.payload?.message);
        },

        // delete locations
        [deleteLocation.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteLocation.fulfilled]: (state, action) => {
            state.isLoading = false;
            toast.success(action.payload.message);
        },
        [deleteLocation.rejected]: (state, action) => {
            state.isLoading = false;
            toast.error(action?.payload?.message);
        },
    },
});

export default locationsSlice.reducer;
