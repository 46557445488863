import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getSearchesData = createAsyncThunk('GET SEARCHES DATA', async (data, thunkAPI) => {
  const response = await axios.get(
    `${URL.USER_SEARCHES}?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&startDate=${
      data.startDate
    }&endDate=${data.endDate}&text=${data.text || ''}`,
    thunkAPI
  );
  return response.data;
});

const initialState = {
  isLoading: false,
  searchesData: null,
};

export const searchesSlice = createSlice({
  name: 'searches',
  initialState,
  reducers: {},
  extraReducers: {
    [getSearchesData.pending]: (state) => {
      state.isLoading = true;
      state.searchesData = null;
    },
    [getSearchesData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.searchesData = action.payload.data;
    },
    [getSearchesData.rejected]: (state, action) => {
      state.isLoading = false;
      state.searchesData = null;
      toast.error(action?.payload?.message);
    },
  },
});

export default searchesSlice.reducer;
