import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getHotels = createAsyncThunk('GET HOTELS', async (data, thunkAPI) => {
  let query = `?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&provider=${data.provider}&refundability=${data.refundability}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&startDate=${data.startDate}&endDate=${data.endDate}&sorting=${data.sorting}&userType=${data.userType}&sortingField=${data.sortingField}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}&cogs=${data.cogs}&cogsPaidDate=${data.cogsPaidDate}`;
  if (data?.agencyId) {
    query = `?agencyId=${data?.agencyId}&page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&provider=${data.provider}&refundability=${data.refundability}&paymentGateway=${data.paymentGateway}&currency=${data.currency}&startDate=${data.startDate}&endDate=${data.endDate}&sorting=${data.sorting}&userType=${data.userType}&sortingField=${data.sortingField}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}&cogs=${data.cogs}&cogsPaidDate=${data.cogsPaidDate}`;
  }
  const response = await axios.get(`${data?.agencyId ? URL.AGENCY_HOTEL : URL.HOTEL}${query}`, thunkAPI);
  return response.data;
});

export const getHotelDetails = createAsyncThunk('GET HOTEL DETAILS', async (data, thunkAPI) => {
  const response = await axios.get(`${URL.HOTEL}/${data.referenceNumber}`, thunkAPI);
  return response.data;
});

export const resendEmailConfirmation = createAsyncThunk('RESEND_HOTEL_EMAIL_CONFIRMATION', async (data, thunkAPI) => {
  const response = await axios.post(URL.RESEND_HOTEL_CONFIRMATION_EMAIL, data, thunkAPI);
  return response.data;
});

export const updateHotelRemarks = createAsyncThunk('UPDATE_HOTEL_REMARKS', async (data, thunkAPI) => {
  const response = await axios.post(URL.UPDATE_HOTEL_REMARKS, data, thunkAPI);
  return response.data;
});

export const updateHotelBooking = createAsyncThunk('UPDATE_HOTEL_BOOKING', async (data, thunkAPI) => {
  const response = await axios.patch(`${URL.UPDATE_HOTEL_BOOKING}/${data.referenceNumber}`, data, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  hotelData: null,
  hotelDetails: null,
};

export const hotelSlice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {},
  extraReducers: {
    // get hotels
    [getHotels.pending]: (state) => {
      state.isLoading = true;
      state.hotelData = null;
    },
    [getHotels.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hotelData = action.payload.data;
    },
    [getHotels.rejected]: (state, action) => {
      state.isLoading = false;
      state.hotelData = null;
      toast.error(action?.payload?.message);
    },

    // update hotel remarks
    [updateHotelRemarks.pending]: (state) => {
      state.isLoading = true;
    },
    [updateHotelRemarks.fulfilled]: (state, action) => {
      state.isLoading = false;
      toast.success('Remarks updated successfully');
    },
    [updateHotelRemarks.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload?.message || 'Error updating remarks');
    },

    // get hotel Details
    [getHotelDetails.pending]: (state) => {
      state.isLoading = true;
      state.hotelDetails = null;
    },
    [getHotelDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hotelDetails = action.payload.data;
    },
    [getHotelDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.hotelDetails = null;
      toast.error(action?.payload?.message);
    },

    // resend hotel confirmation email
    [resendEmailConfirmation.pending]: (state) => {
      state.isLoading = true;
    },
    [resendEmailConfirmation.fulfilled]: (state) => {
      state.isLoading = false;
      // Handle success, e.g., show a success toast message
      toast.success('Email confirmation resent successfully');
    },
    [resendEmailConfirmation.rejected]: (state, action) => {
      state.isLoading = false;
      // Handle error, e.g., show an error toast message
      toast.error(action.payload?.message || 'Error resending email confirmation');
    },

    // update hotel booking
    [updateHotelBooking.pending]: (state) => {
      state.isLoading = true;
    },
    [updateHotelBooking.fulfilled]: (state, action) => {
      state.isLoading = false;
      toast.success(action.payload?.message);
    },
    [updateHotelBooking.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload?.message || 'Error updating cogs data');
    },
  },
});

export default hotelSlice.reducer;
