import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getAdmins = createAsyncThunk('GET ADMIN', async (data, thunkAPI) => {
  const response = await axios.get(
    `${URL.GET_ADMINS}?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text || ''}`,
    thunkAPI
  );
  return response.data;
});

export const getAdminDetails = createAsyncThunk('GET ADMIN DETAILS', async (data, thunkAPI) => {
  const response = await axios.get(`${URL.GET_ADMINS}/${data?.id}`, thunkAPI);
  return response.data;
});

export const createAdmin = createAsyncThunk('CREATE ADMIN', async (data, thunkAPI) => {
  const response = await axios.post(URL.CREATE_ADMIN, data, thunkAPI);
  return response.data;
});

export const updateAdminStatus = createAsyncThunk('UPDATE ADMIN STATUS', async (data, thunkAPI) => {
  const response = await axios.patch(URL.ADMIN_STATUS, data, thunkAPI);
  return response.data;
});

export const editAdmin = createAsyncThunk('EDIT ADMIN', async (data, thunkAPI) => {
  const response = await axios.post(URL.EDIT_ADMIN, data, thunkAPI);
  return response.data;
});

export const deleteAdmin = createAsyncThunk('DELETE ADMIN', async (data, thunkAPI) => {
  const response = await axios.delete(URL.DELETE_ADMIN, data, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  admin: null,
  adminSuccess: false,
  adminDetails: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: {
    // get admins
    [getAdmins.pending]: (state) => {
      state.isLoading = true;
      state.admin = null;
      state.adminDetails = null;
      state.adminSuccess = false;
    },
    [getAdmins.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.admin = action.payload.data;
    },
    [getAdmins.rejected]: (state, action) => {
      state.isLoading = false;
      state.admin = null;
      toast.error(action?.payload?.message);
    },

    // get admin details
    [getAdminDetails.pending]: (state) => {
      state.isLoading = true;
      state.adminDetails = null;
    },
    [getAdminDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.adminDetails = action.payload.data;
    },
    [getAdminDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.adminDetails = null;
      toast.error(action?.payload?.message);
    },

    // update admin status
    [updateAdminStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAdminStatus.fulfilled]: (state, action) => {
      const { admins, adminCount } = state?.admin;
      const index = admins.findIndex((obj) => obj?.id === action?.payload?.data?._id);
      const adminData = admins.at(index);
      const data = {
        ...adminData,
        status: action?.payload?.data?.status === true ? 'Active' : 'Inactive',
      };
      admins.splice(index, 1, data);
      const result = {
        admins,
        adminCount,
      };
      state.admin = result;
      state.isLoading = false;
      toast.success(action?.payload?.message);
    },
    [updateAdminStatus.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action?.payload?.message);
    },

    // create admin
    [createAdmin.pending]: (state) => {
      state.isLoading = true;
      state.adminSuccess = false;
    },
    [createAdmin.fulfilled]: (state, action) => {
      state.adminSuccess = true;
      state.isLoading = false;
      toast.success(action?.payload?.message);
    },
    [createAdmin.rejected]: (state, action) => {
      state.isLoading = false;
      state.adminSuccess = false;
      toast.error(action?.payload?.message);
    },

    // edit admin
    [editAdmin.pending]: (state) => {
      state.isLoading = true;
      state.adminSuccess = false;
    },
    [editAdmin.fulfilled]: (state, action) => {
      state.adminSuccess = true;
      state.isLoading = false;
      toast.success(action?.payload?.message);
    },
    [editAdmin.rejected]: (state, action) => {
      state.isLoading = false;
      state.adminSuccess = false;
      toast.error(action?.payload?.message);
    },

    // delete admin
    [deleteAdmin.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteAdmin.fulfilled]: (state, action) => {
      const { admins, adminCount } = state?.admin;
      const index = admins.findIndex((obj) => obj?.id === action?.payload?.data?._id);
      admins.splice(index, 1);
      const result = {
        admins,
        adminCount,
      };
      state.admin = result;
      state.isLoading = false;
      toast.success(action?.payload?.message);
    },
    [deleteAdmin.rejected]: (state) => {
      state.isLoading = false;
      toast.error(action?.payload?.message);
    },
  },
});

export default adminSlice.reducer;
