import { configureStore } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import userReducer from 'reducer/user';
import dashboardReducer from 'reducer/dashboard';
import adminReducer from 'reducer/admin';
import agencyReducer from 'reducer/agency';
import loaderReducer from 'reducer/loader';
import markupReducer from 'reducer/markup';
import hotelReducer from 'reducer/hotel';
import carReducer from 'reducer/car';
import flightReducer from 'reducer/flight';
import activityReducer from 'reducer/activity';
import cruiseReducer from 'reducer/cruise';
import totalReducer from 'reducer/total';
import settingReducer from 'reducer/settings';
import searchesReducer from 'reducer/searches';
import csvReducer from 'reducer/csv';
import countriesReducer from 'reducer/countries';
import locationsReducer from 'reducer/locations';

export default configureStore({
  reducer: {
    form: formReducer,
    user: userReducer,
    dashboard: dashboardReducer,
    loader: loaderReducer,
    admin: adminReducer,
    agency: agencyReducer,
    markup: markupReducer,
    hotel: hotelReducer,
    cruise: cruiseReducer,
    car: carReducer,
    flight: flightReducer,
    activity: activityReducer,
    total: totalReducer,
    settings: settingReducer,
    searches: searchesReducer,
    csv: csvReducer,
    countries: countriesReducer,
    locations: locationsReducer,
  },
});
