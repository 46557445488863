import React from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

class AxiosAPI extends React.Component {
  constructor() {
    super();

    const API_HOSTNAME = `${process.env.API_URL}:${process.env.API_PORT}`;

    const service = axios.create({
      baseURL: API_HOSTNAME,
      crossDomain: true,
    });
    service.interceptors.response.use(AxiosAPI.handleSuccess, AxiosAPI.handleError);
    this.service = service;
  }

  static handleSuccess = (res) => res;

  static handleError = async (error) => {
    switch (error.response.status) {
      case 401:
        ['loginInfo', 'loginTrue', 'isTrue'].forEach((item) => cookies.remove(item, { path: '/' }));
        localStorage.clear();
        const domain = ['qa', 'uat', 'dev'].includes(process.env.ENV_NAME) ? '.clubxeni.com' : '.xeni.com';
        cookies.remove('x-access-token', { path: '/', domain });
        cookies.remove('x-access-token', {
          path: '/',
        });
        AxiosAPI.redirectTo(document, '/');
        break;

      case 404:
        break;
      default:
        break;
    }
    return Promise.reject(error);
  };

  static redirectTo = (document, path) => {
    document.location = path;
  };

  get(path, thunkAPI) {
    const token = cookies.get('x-access-token');
    return this.service
      .get(path, {
        headers: {
          accept: 'application/json',
          'x-access-token': token,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        throw thunkAPI.rejectWithValue(error.response.data);
      });
  }

  patch(path, payload, thunkAPI) {
    const token = cookies.get('x-access-token');
    return this.service
      .request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
        headers: {
          accept: 'application/json',
          'x-access-token': token,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        throw thunkAPI.rejectWithValue(error.response.data);
      });
  }

  post(path, payload, thunkAPI) {
    const token = cookies.get('x-access-token');
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
        headers: {
          accept: 'application/json',
          'x-access-token': token,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw thunkAPI.rejectWithValue(error.response.data);
      });
  }

  put(path, payload, thunkAPI) {
    const token = cookies.get('x-access-token');
    return this.service
      .request({
        method: 'PUT',
        url: path,
        responseType: 'json',
        data: payload,
        headers: {
          accept: 'application/json',
          'x-access-token': token,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        throw thunkAPI.rejectWithValue(error.response.data);
      });
  }

  delete(path, payload, thunkAPI) {
    const token = cookies.get('x-access-token');
    return this.service
      .request({
        method: 'DELETE',
        url: path,
        responseType: 'json',
        data: payload,
        headers: {
          accept: 'application/json',
          'x-access-token': token,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        throw thunkAPI.rejectWithValue(error.response.data);
      });
  }
}

export default new AxiosAPI();
